export function downloadBlob(blob: Blob, filename = 'download') {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = url;
    a.download = filename || 'download';

    const handleClick = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', handleClick);
        }, 150);
    };

    a.addEventListener('click', handleClick, false);
    a.click();
}
