import React from 'react';
import { getMediaByRelation } from '@alterpage/gatsby-plugin-image';

import {
    sectionBox,
    grid,
    container,
    radiusBox,
    orderTitle,
    divider,
    wrapper,
    summary,
    columns,
    column,
    columnTitle,
    columnValue,
    invoiceBox,
} from './user-order-preview.module.scss';
import { ISection } from '../../models/section.model';
import { relations } from '../../config/relations';
import { useOrder } from '../../hooks/use-order';
import useTranslations from '../../hooks/use-translations';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import { useClientPermission } from '../../hooks/use-client-permission';
import { getUserTokenData } from '../../utils/get-user-token-data';
import { downloadPdf } from '../../utils/download-pdf';

import Section from '../hoc/section';
import DashboardHeader from '../molecules/dashboard-header';
import RadiusBox from '../hoc/radius-box';
import Button from '../atoms/button';
import DetailsColumn from '../atoms/details-column';
import NoPermissionInfo from '../organisms/no-permission-info';
import FetchStatusInfo from '../molecules/fetch-status-info';

export interface IUserOrderPreviewProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
    type: 'client' | 'candidate';
}

const UserOrderPreview: React.FC<IUserOrderPreviewProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
    type,
}) => {
    const { style, sectionId, css } = section;
    const t = useTranslations('ClientOrderPreview');
    const order = useOrder({ queries: ['single'] });
    const canShop = useClientPermission(['can-shop']);
    const token = getUserTokenData()?.token || '';

    const invoiceUrl = getMediaByRelation({
        media: order.single.data?.media,
        relation: relations.invoiceDocument,
        useFallback: false,
    })?.url;

    const invoiceProformaUrl = getMediaByRelation({
        media: order.single.data?.media,
        relation: relations.invoiceProformaDocument,
        useFallback: false,
    })?.url;

    const getInvoiceData = () => {
        if (order.single.data) {
            const invoiceData = [
                `${order.single.data.addresses.payment.street} ${
                    order.single.data.addresses.payment.houseNumber
                } ${
                    order.single.data.addresses.payment.apartmentNumber
                        ? `/${order.single.data.addresses.payment.apartmentNumber}`
                        : ''
                }`,
                `${order.single.data.addresses.payment.zipCode} ${order.single.data.addresses.payment.city}`,
            ];

            if (type === 'client') {
                invoiceData.unshift(
                    `${t.tax}: ${order.single.data.addresses.payment.taxNumber}`,
                    `${order.single.data.addresses.payment.companyName}`
                );
            }

            return invoiceData;
        }
    };

    const handleInvoiceDownload = (url: string) => {
        return async () => {
            await downloadPdf(url, token, order.single.data?.uid || '');
        };
    };

    if (type === 'client' && !canShop) {
        return <NoPermissionInfo isFullHeight={true} reason="role" />;
    }

    return (
        <Section
            className={`${sectionBox} ${className}`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <div className={container}>
                {(order.single.isLoading || order.single.isError) && (
                    <FetchStatusInfo
                        isError={order.single.isError}
                        error={t.fetchError}
                        isLoading={order.single.isLoading}
                    />
                )}
                {order.single.data && (
                    <>
                        <DashboardHeader
                            hint={type === 'client' ? t.hintClient : t.hintCandidate}
                            TitleTag={TitleTag}
                        >
                            {t.title}
                        </DashboardHeader>
                        <RadiusBox className={radiusBox}>
                            <p className={orderTitle}>
                                {t.orderId}
                                {order.single.data.uid}
                            </p>
                            <div className={divider} />
                            <div className={wrapper}>
                                <div className={columns}>
                                    <DetailsColumn
                                        className={column}
                                        label={t.createdAt}
                                        values={[
                                            `${getDateFromUnixTimestamp(
                                                order.single.data.createdAt,
                                                'dash'
                                            )}`,
                                        ]}
                                    />
                                    <DetailsColumn
                                        className={column}
                                        label={t.data}
                                        values={getInvoiceData()}
                                    />
                                    <DetailsColumn
                                        className={column}
                                        label={t.status}
                                        values={[`${order.single.data.orderStatus.name}`]}
                                        valueColor={
                                            order.single.data.orderStatus.colorText
                                                ? order.single.data.orderStatus.colorText
                                                : 'black'
                                        }
                                    />
                                    <DetailsColumn
                                        className={column}
                                        label={t.ordered}
                                        values={[`${order.single.data.items[0].variant.name}`]}
                                    />
                                </div>
                                {(invoiceUrl || invoiceProformaUrl) && (
                                    <div className={invoiceBox}>
                                        {invoiceProformaUrl && (
                                            <Button
                                                onClick={handleInvoiceDownload(invoiceProformaUrl)}
                                                stylePreset={'secondary'}
                                            >
                                                {t.downloadProforma}
                                            </Button>
                                        )}
                                        {invoiceUrl && (
                                            <Button
                                                onClick={handleInvoiceDownload(invoiceUrl)}
                                                stylePreset={'secondary'}
                                            >
                                                {t.download}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className={divider} />
                            <div className={summary}>
                                <div className={column}>
                                    <p className={columnTitle}>{t.summary}</p>
                                    <p className={columnValue}>
                                        {order.single.data.finalPrice.grossDisplay}
                                    </p>
                                </div>
                            </div>
                        </RadiusBox>
                    </>
                )}
            </div>
        </Section>
    );
};

export default UserOrderPreview;
