import client from '../api-clients/client';

import { downloadBlob } from './download-blob';

function getPdf(url: string, token: string) {
    return client.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
    });
}

export async function downloadPdf(url: string, token: string, filename: string) {
    try {
        const res = await getPdf(url, token);
        const pdfFileBlob = new Blob([res.data], { type: 'application/pdf' });
        downloadBlob(pdfFileBlob, filename);
    } catch {}
}
